import Layout from "@/components/layout/Layout";
import SectionCenter from "@/components/layout/SectionCenter";
import SectionTitle from "@/components/layout/SectionTitle";
import { ReplyIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Error404() {
  const { t } = useTranslation("common");

  return (
    <Layout>
      <GatsbySeo
        title={t("404.seo.title")}
        description={t("404.seo.description")}
        noindex={true}
      />

      <SectionTitle title={t("404.title")} subtitle={t("404.subtitle")}>
        {t("404.description")}
      </SectionTitle>
      <SectionCenter>
        <div className="sm:flex sm:justify-center sm:mx-auto">
          <Link
            to="/"
            className="flex justify-center items-center w-full sm:w-auto px-8 md:px-10 py-3 md:py-4 text-base md:text-lg font-medium text-white bg-green-600 hover:bg-green-700 rounded-md border border-transparent shadow"
          >
            {t("404.action.label")}
            <ReplyIcon className="ml-3 -mr-1 h-6 w-6" aria-hidden="true" />
          </Link>
        </div>
      </SectionCenter>
    </Layout>
  );
}
